import React, { useContext } from 'react';
import '../Styles/Idgenerator.css';
import { Usercontext } from '../Usercontext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import profile from '../Assets/profile.jpg';
import psign from "../Assets/psign.png";
import du from "../Assets/du.png"
export default function Idgenerator() {
  const { user } = useContext(Usercontext);

  const downloadidpdf = () => {
    const capture = document.querySelector('.capture-area'); 
    html2canvas(capture).then((canvas) => {
      const imgdata = canvas.toDataURL('img/png');
      const doc = new jsPDF('p', 'mm', 'a4'); 

      const pageWidth = doc.internal.pageSize.getWidth() /2;
      const pageHeight = doc.internal.pageSize.getHeight() / 2; 


      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

      const scaledWidth = imgWidth * ratio;
      const scaledHeight = imgHeight * ratio;

      const x = (pageWidth - scaledWidth) / 2;
      const y = (pageHeight - scaledHeight) / 2;

      doc.addImage(imgdata, 'PNG', x, y, scaledWidth, scaledHeight);
      doc.save('idcard.pdf');
    });
  };

  const isDownloadDisabled = !user.photo || !user.dob || !user.bloodgroup || !user.phone;

  return (
    <div className='black-outside'>
      <p className='text-danger'><b>Note: Please fill all details in profile before getting ID card</b></p>
      <div className='capture-area'>
        <div className='card front idcard m-5 d-flex'>
          <div className='text-back'>
          <img src={du} alt='logo' className='logo-id'/>
          <h5 className='text-center p-2 '>Mysuru District B C M Hostel Senior Students Society(R .)</h5>
          </div>
          <div className='idholder'>
            <img src={user.photo ? user.photo : profile} className='idimage' alt='idimage' />
            <div className='main-id-fin w-75'>
              <div className='div-some'>
              <p><b>Name :&nbsp;&nbsp;{user.name}</b></p>
              <p><b>Id :</b>&nbsp;&nbsp;{user.id}</p>
              <p><b>Dob :</b>&nbsp;&nbsp;{user.dob}</p>
              <p><b>Blood Group :</b>&nbsp;&nbsp;{user.bloodgroup}</p>
              <p><b>Mobile :</b>&nbsp;&nbsp;{user.phone}</p>
              </div>
            <div className='sign-box'>
              <img src={psign} alt='signature' className='' />
            </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className='btn btn-primary w-50 m-3'
        onClick={downloadidpdf}
        disabled={isDownloadDisabled}
      >
        Download
      </button>
    </div>
  );
}
