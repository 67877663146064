import React, { useContext, useState } from 'react';
import '../Styles/Homepage.css';
import du from "../Assets/du.png";
import { toast } from 'react-toastify';
import { baseurl } from '../App';
import shiv from "../Assets/shivswamy.jpeg";
import { IoMdCall } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import dpay from "../Assets/dpay.jpeg";
import { Usercontext } from '../Usercontext';
import psign from "../Assets/psign.png";
import jsPDF from 'jspdf';
import devrajurs from "../Assets/Devaraj_Urs.png"
export default function Homepage() {
  const [amount, setamount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [payment, setpayment] = useState(false);
  const { user } = useContext(Usercontext);


  const generateInvoice = () => {
    const doc = new jsPDF();
    doc.setFontSize(20);
    doc.text('BCM Hostel Senior Students Association, Mysore (R)', 10, 10);
    doc.setFontSize(14);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 10, 20);
    doc.text(`Name: ${user.name}`, 10, 30);
    doc.text(`Email: ${user.email}`, 10, 40);
    doc.text(`User ID: ${user.id}`, 10, 50);
    doc.text(`Transaction ID: ${transactionId}`, 10, 60);
    doc.text(`Amount Donated: ₹${amount}`, 10, 70);
    doc.addImage(psign, 'JPEG', 10, 120, 50, 30); 
    doc.save(`Invoice_${user.name}_${new Date().toLocaleDateString()}.pdf`);
  };

  const handlePaymentSuccess = () => {
    if (transactionId) {
      toast.success('Payment Successful');
      setpayment(true);
      setShowModal(false);
      generateInvoice();
    } else {
      toast.error('Please enter the transaction ID');
    }
  };

  const handlePaymentFailure = () => {
    toast.error('Payment Failed');
    setShowModal(false);
  };

  const openModal = () => {
    if (!amount) {
      toast.error('Please enter amount');
      return;
    }
    setShowModal(true);
  };

  return (
    <div>
      <div className='mainpage'>
        <h2 className='text-center'>Mysuru District B C M Hostel Senior Students Society(R . )</h2>
        <b className='d-flex justify-content-center align-self-end; justify'>
          <h2 className='text-center'>ಬಿ  ಸಿ  ಎಂ ಹಾಸ್ಟೆಲ್ ಹಿರಿಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಸಂಘ, ಮೈಸೂರು (ರಿ . )</h2>
          <p>Rg. No :-DRMY/SOR/810/2023-2024</p>
        </b>
        <div className='home-main'>
          <img src={devrajurs} alt='devraj ars' className='m-3 home-img' />
          <div className=''>
            <h6 className='fs-5 my-3 justify'>
              Welcome to the official alumni network of BCM Hostel, a place where memories are cherished, friendships are rekindled, and new connections are made. Founded in 2023 by B. Shivaswamy (Joint Secretary to the Chief Minister, Government of Karnataka.), our community honors the legacy of D.Dev Raj Urs, whose vision brought BCM Hostel to life in the heart of Mysuru.
            </h6>
            <h6 className='fs-6 my-3 mb-5 justify'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Since its establishment, BCM Hostel, built by the esteemed D.DevRaj Urs, has been a cornerstone of community and learning in Mysuru. Our hostel has nurtured countless individuals who have gone on to make significant contributions in various fields.
            </h6>
            {!user?.id ? null : (
              <div>
                <p className='text-secondary fw-bolder m-2s'>Give Donation for our Association</p>
                <input
                  type='number'
                  placeholder='Donation amount'
                  className='form-control'
                  value={amount}
                  onChange={(e) => setamount(e.target.value)}
                />
                <div className='d-flex justify-content-center'>
                  <button className='btn btn-dark m-2 w-50' type='button' onClick={openModal}>
                    Pay Donation
                  </button>
                </div>
              </div>
            )}
          </div>
          <img src={shiv} alt='devraj ars' className='m-3 home-img' />
        </div>
      </div>
      <div className='afooter'>
        <div className='mt-5 mx-5 mb-3 sinc'>
          <center>
            <h5 className='mb-3'>Contact Us</h5>
            <p><IoMdCall />  97397 19580</p>
            <p>Madhusudhana S M</p>
            <p><MdEmail /> bcmhostelalumni@gmail.com</p>
          </center>
        </div>
        <div className='mt-5 mx-5 mb-3 sinc'>
          <center>
            <h5 className='mb-3'>&copy; Since 2023</h5>
            <p>Founded By B Shivaswamy</p><br /><br /><br /><br />
            <p>Rg. No :-DRMY/SOR/810/2023-2024</p>
          </center>
        </div>
        <div className='m-2'>
          <center>
            <h5>Donation For  Association</h5>
            <img src={dpay} alt='qr code' className='qr' />
          </center>
        </div>
      </div>

      {showModal && (
        <div className='d-flex justify-content-center align-items-center'>
          <div className="modal-backdrop-custom"></div>
          <div className="modal show d-block modal-custom" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Payment Details</h5>
                  <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <p>Donation Amount: ₹{amount}</p>
                  <img src={dpay} alt="QR Code" style={{ width: '200px' }} />
                  <div className="form-group mt-3 d-flex justify-content-center gap-3">
                    <label htmlFor="transactionId"><strong>Enter Transaction ID</strong></label>
                    <input
                      className='form-control w-50'
                      type='text'
                      id='transactionId'
                      value={transactionId}
                      onChange={(e) => setTransactionId(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-center align-items-center">
                  <button type="button" className="btn btn-success" onClick={handlePaymentSuccess}>
                    Payment Successful
                  </button>
                  <button type="button" className="btn btn-danger" onClick={handlePaymentFailure}>
                    Payment Failed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
